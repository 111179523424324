import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CalendarMonth, Search } from '@mui/icons-material';
import blog_icon from '../assets/blog_icon.png';
import defaultBlogImage from "../assets/blog.png";
import CustomLoader from '../components/loader';
import toast from 'react-hot-toast';
import { getBlogs } from '../Services/dashboard';
import scholar1 from '../assets/scholarship1.png';
import cherons from "../assets/chevrons-right.png";
import { Helmet } from 'react-helmet-async';

const Blog = () => {
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [blogsPerPage] = useState(9);
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const checkForRedirects = () => {
            console.log("Checking for redirects");
            console.log("Blogs:", blogs);
            
            if (localStorage.getItem('visaGuidance') && blogs.length > 0) {
                console.log("Visa guidance flag found");
                const visaBlog = blogs.find(blog => 
                    blog.heading.toLowerCase().trim() === 'study visa guidance: your gateway to studying abroad'
                );
                if (visaBlog) {
                    console.log("Visa blog found, redirecting");
                    navigate('/blog-details', { state: visaBlog });
                    localStorage.removeItem('visaGuidance');
                } else {
                    console.log("Visa blog not found");
                }
            }
            
            if (localStorage.getItem('accommodationGuidance') && blogs.length > 0) {
                console.log("Accommodation guidance flag found");
                const accommodationBlog = blogs.find(blog => 
                    blog.heading.toLowerCase().trim() === 'accommodation guidance: finding your perfect home away from home'
                );
                if (accommodationBlog) {
                    console.log("Accommodation blog found, redirecting");
                    navigate('/blog-details', { state: accommodationBlog });
                    localStorage.removeItem('accommodationGuidance');
                } else {
                    console.log("Accommodation blog not found");
                }
            }
            
            if (localStorage.getItem('financialPlanning') && blogs.length > 0) {
                console.log("Financial planning flag found");
                const financialBlog = blogs.find(blog => 
                    blog.heading.toLowerCase().trim() === 'financial planning for studies abroad: securing your future with confidence'
                );
                if (financialBlog) {
                    console.log("Financial planning blog found, redirecting");
                    navigate('/blog-details', { state: financialBlog });
                    localStorage.removeItem('financialPlanning');
                } else {
                    console.log("Financial planning blog not found");
                }
            }
            
            if (localStorage.getItem('redirectToAboutUs') && blogs.length > 0) {
                console.log("About Us flag found");
                const aboutUsBlog = blogs.find(blog => 
                    blog.heading.toLowerCase().trim() === 'about us'
                );
                if (aboutUsBlog) {
                    console.log("About Us blog found, redirecting");
                    navigate('/blog-details', { state: aboutUsBlog });
                    localStorage.removeItem('redirectToAboutUs');
                } else {
                    console.log("About Us blog not found");
                }
            }
        };
    
        if (blogs.length > 0) {
            checkForRedirects();
        }
    }, [blogs, navigate]);

    const getBlogsData = async () => {
        setLoading(true);
        try {
            const response = await getBlogs();
            if (!response.data?.error) {
                setBlogs(response?.data?.data.reverse()||[]);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Failed to fetch blogs');
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (date) => {
        const d = new Date(date);
        const month = d.toLocaleString("default", { month: "short" });
        const day = d.getDate();
        const year = d.getFullYear();
        return `${month} ${day}, ${year}`;
      };
    
    const capitaliseFirstWord = (str) => {
        return str
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
    }

    useEffect(() => {
        getBlogsData();
    }, []);

    // Pagination logic
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = 0;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    const handleLoadMore = () => setCurrentPage(prevPage => prevPage + 1);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };

    const filteredBlogs = blogs.filter(blog => 
        blog.heading.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="container py-4 course_container">
             <Helmet>
        <title>Our Educational Blogs || Edulley Blogs </title>
        <meta name="description" content="Stay updated with Edulley's educational blogs. Our expert articles provide valuable insights and resources to enrich your learning experience." />
        <link rel="canonical" href="https://www.edulley.com/blog" />
      </Helmet>
            <div className="py-5"></div>
            <div className="row justify-content-between inner_course mt-0">
                <div className="row">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h1 className="font-lato fw-bold">
                                <img src={blog_icon} className="img-fluid" alt="" />
                                <span className="mt-1 ml-2" style={{ fontFamily: "Lato", fontWeight: 700 }}>Blogs</span>
                            </h1>
                        </div>
                        <div className="search-filter" style={{ position: "relative" }}>
    <input
        type="text"
        className="form-control"
        placeholder=" Search Blogs"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{
            fontFamily: "Lato", fontWeight: 500,
            borderRadius: "20px",
            paddingLeft: "2rem"
        }}
    />
    <span style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)" }}>
       <Search/>
    </span>
</div>

                    </div>
                    <p className="what-we-can-do-description mb-3" style={{ fontFamily: "Lato", fontWeight: 500 }}>
                        Transforming the landscape of Education with revolutionary technology
                    </p>
                </div>
                {loading ? (
                    <CustomLoader />
                ) : (
                    <div className="col-md-9">
                        <div className="row">
                            {filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog).map(blog => (
                                <div className="col-md-4" key={blog._id}>
                                    <div onClick={() => navigate('/blog-details', { state: blog })}>
                                        <div className="countries cursor-pointer uni_card blog-card">
                                            <img src={blog.bannerImage || defaultBlogImage} alt="Blog" className="university-image img-fluid" style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
                                            <div className="p-3">
                                                <p className="text-secondary d-flex align-items-center gap-2" style={{ fontSize: '13px', fontFamily: "Lato", fontWeight: 500, color: "#8D98A4" }}>
                                                    <CalendarMonth style={{ color: "#8D98A4" }} />
                                                    {formatDate(blog?.createdAt)}
                                                    <div className="blog-tags">
                                                        {blog.tags.map(tag => (
                                                            <span className="badge mt-2 me-2 p-2" style={{ backgroundColor: "#FFF0F0", color: "#000000" }} key={tag}>{tag}</span>
                                                        ))}
                                                    </div>
                                                </p>
                                                <p className="mt-2 text-truncate" style={{ maxHeight: '3rem', overflow: 'hidden', fontFamily: "Lato", fontWeight: 500 }} >
                                                    {capitaliseFirstWord(blog?.heading)}
                                                </p>
                                                {blog.heading.length > 30 && (
                                                    <div className="tooltip"> {/* Bootstrap tooltip */}
                                                        <span className="tooltiptext" style={{ fontFamily: "Lato", fontWeight: 500 }}>{blog.heading}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {indexOfLastBlog < filteredBlogs.length && (
                            <div className="d-flex justify-content-center mt-4">
                                <button className="explore-button py-2 px-4" onClick={handleLoadMore} style={{ fontFamily: "Lato", fontWeight: 500 }}>
                                    Load More <img src={cherons} alt="Home" />
                                </button>
                            </div>
                        )}
                    </div>
                )}
                <div className="col-md-3">
                    <div className="s_img_card text-center">
                        <p style={{ fontFamily: "Lato", fontWeight: 700 }}>Look at all the courses</p>
                        <button onClick={()=>{navigate("/courses")}} style={{ fontFamily: "Lato", fontWeight: 500 }} className="explore-button py-2 fw-light mt-2">Explore All Courses</button>
                        <p className="my-2" style={{ fontFamily: "Lato", fontWeight: 700 }}>OR</p>
                        <a href="https://wa.me/message/SMDIYPHGQFQRC1" target="_blank" className="" style={{ color: "#ff5573", cursor: "pointer", fontFamily: "Lato", fontWeight: 500 }} >Chat with us </a>
                    </div>
                    <div className="s_img_card text-center mt-2">
                        <img src={scholar1} alt="" />
                        <p style={{ fontFamily: "Lato", fontWeight: 700 }}>Let’s look at the scholarships available for you</p>
                        <button onClick={()=>{navigate("/scholarship")}} className="explore-button py-2 fw-light mt-2">Explore All Scholarship</button>
                        <p className="my-2" style={{ fontFamily: "Lato", fontWeight: 700 }}>OR</p>
                        <a href="https://wa.me/message/SMDIYPHGQFQRC1" target="_blank" className="" style={{ color: "#ff5573", cursor: "pointer", fontFamily: "Lato", fontWeight: 500 }} >Chat with us </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;
