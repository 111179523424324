import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const wave = keyframes`
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-20px) scale(0.8);
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
`;

const LoaderWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  animation: ${rotate} 3s linear infinite;
`;

const Circle = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${props => props.color};
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  animation: ${wave} 1.5s ease-in-out infinite;
  animation-delay: ${props => props.delay}s;
  box-shadow: 0 0 15px ${props => props.color};
  transform-origin: center center;
`;

const ConnectingLine = styled.div`
  position: absolute;
  width: 2px;
  height: ${props => props.height}px;
  background: linear-gradient(to bottom, ${props => props.startColor}, ${props => props.endColor});
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  transform: rotate(${props => props.rotate}deg);
  opacity: 0.6;
`;

const CustomLoader = () => {
  const circles = [
    { color: '#FF5573', top: 0, left: 50, delay: 0 },
    { color: '#4A90E2', top: 25, left: 85, delay: 0.2 },
    { color: '#FF5573', top: 50, left: 50, delay: 0.4 },
    { color: '#4A90E2', top: 75, left: 85, delay: 0.6 },
    { color: '#FF5573', top: 100, left: 50, delay: 0.8 },
    { color: '#4A90E2', top: 25, left: 15, delay: 0.2 },
    { color: '#4A90E2', top: 75, left: 15, delay: 0.6 }
  ];

  const lines = [
    { startColor: '#FF5573', endColor: '#4A90E2', height: 30, top: 10, left: 50, rotate: 45 },
    { startColor: '#4A90E2', endColor: '#FF5573', height: 30, top: 35, left: 50, rotate: -45 },
    { startColor: '#FF5573', endColor: '#4A90E2', height: 30, top: 60, left: 50, rotate: 45 },
    { startColor: '#4A90E2', endColor: '#FF5573', height: 30, top: 85, left: 50, rotate: -45 }
  ];

  return (
    <Container>
      <Backdrop />
      <LoaderWrapper>
        {circles.map((circle, index) => (
          <Circle key={index} {...circle} />
        ))}
        {lines.map((line, index) => (
          <ConnectingLine key={`line-${index}`} {...line} />
        ))}
      </LoaderWrapper>
    </Container>
  );
};

export default CustomLoader;